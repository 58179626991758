// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-asr-tts-tsx": () => import("./../../../src/pages/asr-tts.tsx" /* webpackChunkName: "component---src-pages-asr-tts-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-chat-kit-tsx": () => import("./../../../src/pages/chatKit.tsx" /* webpackChunkName: "component---src-pages-chat-kit-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dataset-tsx": () => import("./../../../src/pages/dataset.tsx" /* webpackChunkName: "component---src-pages-dataset-tsx" */),
  "component---src-pages-developer-kit-tsx": () => import("./../../../src/pages/developer-kit.tsx" /* webpackChunkName: "component---src-pages-developer-kit-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-ide-tsx": () => import("./../../../src/pages/ide.tsx" /* webpackChunkName: "component---src-pages-ide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-tsx": () => import("./../../../src/pages/mobile.tsx" /* webpackChunkName: "component---src-pages-mobile-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-purchases-tsx": () => import("./../../../src/pages/purchases.tsx" /* webpackChunkName: "component---src-pages-purchases-tsx" */),
  "component---src-pages-puzzlelib-tsx": () => import("./../../../src/pages/puzzlelib.tsx" /* webpackChunkName: "component---src-pages-puzzlelib-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-why-sova-tsx": () => import("./../../../src/pages/why-sova.tsx" /* webpackChunkName: "component---src-pages-why-sova-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

