const settingsConfig = {
  app: "SOVA Portal",
  language: { languageCode: "en-EN", languageText: "EN" },
  blogTagFilter: {
    tagSelected: "",
    posts: [],
    newPostsQuantity: 1,
  },
  docs: {
    navShownIdx: null,
    showList: false,
  },
}
export default settingsConfig
