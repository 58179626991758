import React from "react"
import { StoreContext } from "storeon/react"
import store from "./src/store/store"

//НУЖНО УБРАТЬ ОТСЮДА!!!
import "./src/styles/common/global.css"
import "./src/styles/index.css"
export const wrapRootElement = ({ element }) => {
  return <StoreContext.Provider value={store}>{element}</StoreContext.Provider>
}

export { wrapPageElement } from "./gatsby-ssr"
