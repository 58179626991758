import React from "react"

const PageContext = React.createContext({})

interface PageContextProps {
  langKey?: string
  slug?: string
  originalPath?: string
  locale?: string
}

export const PageContextProvider = ({ pageContext, children }: { pageContext: PageContextProps; children: any }) => {
  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
}

export const usePageContext = () => React.useContext(PageContext)
