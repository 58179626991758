import { StoreonModule } from "storeon"
import Store from "../@types/store"

import settingsConfig from "../configs/settings"

const settingsModule: StoreonModule<Store> = store => {
  store.on("@init", () => ({ settings: settingsConfig }))
  store.on("updateLanguage", ({ settings }, language: Language) => {
    settings.language = language
    return { settings }
  })
  store.on("initPostsList", ({ settings }, posts: any) => {
    settings.blogTagFilter.posts = posts
    return { settings }
  })
  store.on("updatePostsList", ({ settings }, posts: any) => {
    settings.blogTagFilter.posts = posts
    return { settings }
  })
  store.on("selectPostTag", ({ settings }, tagSelected) => {
    settings.blogTagFilter.tagSelected = tagSelected
    return { settings }
  })
  store.on("updateNewPostsAmount", ({ settings }, amount) => {
    settings.blogTagFilter.newPostsQuantity += amount
    return { settings }
  })
  store.on("returnPostsToInitialAmount", ({ settings }) => {
    settings.blogTagFilter.newPostsQuantity = 1
    return { settings }
  })
  store.on("updateDocsShownIdx", ({ settings }, idx) => {
    settings.docs.navShownIdx = idx
    return { settings }
  })
}

export default settingsModule
