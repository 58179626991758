import { createContext } from "react"
import { createStoreon } from "storeon"
import { storeonDevtools /* storeonLogger */ } from "storeon/devtools"
import { customContext } from "storeon/react"
import { persistState } from "@storeon/localstorage"

import settingsModule from "./events/settings"

const storageData = typeof window !== "undefined" && persistState(["settings"])

let storeEvents = [settingsModule, storageData]

if (process.env.NODE_ENV !== "production") {
  storeEvents = [storeonDevtools, ...storeEvents]
}

const store = createStoreon(storeEvents)

const CustomContext = createContext(store)

export const useStoreon = customContext(CustomContext)

export default store
